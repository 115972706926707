
<template>
  <div class="app flex-row align-items-center" id="login-page">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-card-group>
            <b-card no-body class="p-1">
              <b-card-body>
                <h3 class="text-center">{{aLang.header_goChangePassword}}</h3>
                <b-alert
                  variant="danger"
                  dismissible
                  :show="mData.dataDefault.showDismissibleAlertError"
                  @dismissed="mData.dataDefault.showDismissibleAlertError=false"
                >{{mData.dataDefault.error}}</b-alert>
                <b-alert
                  variant="success"
                  dismissible
                  :show="mData.dataDefault.showDismissibleAlertSuccess"
                  @dismissed="mData.dataDefault.showDismissibleAlertSuccess=false"
                >{{mData.dataDefault.success}}</b-alert>

                <el-form
                  :model="mData.dataForm"
                  :rules="mData.validForm"
                  ref="myForm"
                  label-width="100%"
                  label-position="top"
                  @submit.native.prevent
                >
                  <el-form-item :label="aLang.user_nameAccount" prop="account">
                    <el-input
                      prefix-icon="el-icon-user"
                      v-model.trim="mData.dataForm.account"
                      clearable
                      disabled
                    ></el-input>
                  </el-form-item>

                  <el-form-item :label="aLang.user_oldPassword" prop="oldPassword">
                    <el-input
                      prefix-icon="el-icon-unlock"
                      v-model.trim="mData.dataForm.oldPassword"
                      show-password
                    ></el-input>
                  </el-form-item>

                  <el-form-item :label="aLang.user_newPassword" prop="newPassword">
                    <el-input
                      ref="newPassword"
                      prefix-icon="el-icon-lock"
                      v-model.trim="mData.dataForm.newPassword"
                      show-password
                    ></el-input>
                  </el-form-item>

                  <el-form-item :label="aLang.user_reEnterPass" prop="rePassword">
                    <el-input
                      prefix-icon="el-icon-lock"
                      v-model.trim="mData.dataForm.rePassword"
                      show-password
                    ></el-input>
                  </el-form-item>

                  <el-form-item class="text-center mb-2">
                    <el-button
                      type="primary"
                      @click="fn_submitForm()"
                      v-ladda
                      native-type="submit"
                    >{{aLang.c_update}}</el-button>
                    <el-button @click="fn_go()" type="info" plain>{{aLang.c_back}}</el-button>
                  </el-form-item>
                </el-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AuthService from "../../service/AuthService";
import LanguageService from "../../service/LanguageService";
import ValidService from "../../service/ValidService";
import MethodService from "../../service/MethodService";
export default {
  name: "ChangePassword",
  data() {
    return {
      aLang: LanguageService.lang,
      mData: {
        dataDefault: {
          error: "",
          success: "",
          showDismissibleAlertError: false,
          showDismissibleAlertSuccess: false
        },
        dataForm: {
          account: "",
          oldPassword: "",
          newPassword: "",
          rePassword: ""
        },
        validForm: {
          account: [ValidService.required],
          oldPassword: [ValidService.required],
          newPassword: [ValidService.required, ValidService.checkLength(6)],
          rePassword: [ValidService.required, ValidService.checkLength(6)]
        }
      }
    };
  },

  methods: {
    fn_submitForm() {
      this.$refs["myForm"].validate(valid => {
        if (valid) {
          if (
            this.mData.dataForm.newPassword != this.mData.dataForm.rePassword
          ) {
            this.mData.dataDefault.showDismissibleAlertError = true;
            this.mData.dataDefault.showDismissibleAlertSuccess = false;
            this.mData.dataDefault.error =
              LanguageService.lang.c_changePasswordErrorReEnterNew;
            MethodService.stopLadda();
            return;
          }
          if (
            this.mData.dataForm.newPassword == this.mData.dataForm.oldPassword
          ) {
            this.mData.dataDefault.showDismissibleAlertError = true;
            this.mData.dataDefault.showDismissibleAlertSuccess = false;
            this.mData.dataDefault.error =
              LanguageService.lang.c_changePasswordErrorOldNew;
            MethodService.stopLadda();
            return;
          }

          AuthService.changePassword(
            this.mData.dataForm.account,
            this.mData.dataForm.oldPassword,
            this.mData.dataForm.newPassword
          )
            .then(res => {              
              AuthService.logout(true)
                .then(res => {
                  this.$message({
                    showClose: true,
                    message: LanguageService.lang.c_changePasswordSuccess +". Bạn hãy đăng nhập lại bằng mật khẩu mới",
                    type: "success",
                    duration: 8000 //8s
                  });
                  this.$router.push({ name: "Auth-Login" });
                })
                .catch(error => {});

              MethodService.stopLadda();
              return true;
            })
            .catch(error => {
              this.mData.dataDefault.showDismissibleAlertError = true;
              this.mData.dataDefault.showDismissibleAlertSuccess = false;
              this.mData.dataDefault.error =
                LanguageService.lang.c_changePasswordError;
              MethodService.stopLadda();
              return false;
            });
        } else {
          MethodService.stopLadda();
          return false;
        }
      });
    },
    fn_resetForm() {
      this.$refs["myForm"].resetFields();
      this.fn_initData();
      this.mData.dataDefault.showDismissibleAlertSucces = false;
      this.mData.dataDefault.showDismissibleAlertError = false;
    },
    async fn_initData() {
      let currentUser =  MethodService.getCurrentUser();
      this.mData.dataForm.account = currentUser.account;
    },
    fn_go() {
      this.$router.go(-1);
    }
  },

  mounted() {
    this.fn_initData();
  },
  created() {}
};
</script>